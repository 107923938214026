import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Content from './Content'
import Accordion from './Accordion'
import backgroundImage from '../images/taetigkeitsbereich_background.jpg'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Wrapper = styled.section`
  background: url(${backgroundImage}) center top no-repeat;
  background-size: fixed;
  padding-top: 160px;
  padding-bottom: 160px;

  @media (max-width: 1024px) {
    background: #f2f2f2;
  }

  @media (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (max-width: 375px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

const allServicesQuery = graphql`
  query ServicesQuery {
    allContentfulService(sort: { fields: title, order: ASC }) {
      edges {
        node {
          node_locale
          title
          content {
            json
          }
        }
      }
    }
  }
`

const Services = () => {
  const intl = useIntl()
  const data = useStaticQuery(allServicesQuery)

  const items = data.allContentfulService.edges.map(({ node }) => {
    return {
      title: node.title,
      content: node.content.json,
      locale: node.node_locale,
    }
  })

  // Filter languages
  const languageItems = items.filter(item => {
    return item.locale === intl.locale
  })

  return (
    <Wrapper>
      <Content>
        <Fade bottom>
          <Accordion items={languageItems} />
        </Fade>
      </Content>
    </Wrapper>
  )
}

export default Services
