import React from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content from '../components/Content'
import Text from '../components/Text'
import Intro from '../components/Intro'
import Title from '../components/Title'
import Spacer from '../components/Spacer'
import Services from '../components/Services'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const ServicesPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'nav.services' })} />
      <Intro image={data.introImage} />
      <Content>
        <Title align="center">
          {intl.formatMessage({ id: 'services.heading' })}
        </Title>
        <Fade bottom>
          <Text align="center" size="intro">
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'services.intro' }),
              }}
            />
          </Text>
        </Fade>
        <Spacer />
      </Content>
      <Services />
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query {
    introImage: file(relativePath: { eq: "taetigkeitsbereich_head.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
