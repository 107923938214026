import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import AnimateHeight from 'react-animate-height'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import arrowDown from '../images/accordion-down.svg'

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1024px) {
    display: block;
  }

  @media (max-width: 480px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  @media (max-width: 3750px) {
    margin-left: -10px;
    margin-right: -10px;
  }
`

const Column = styled.div`
  width: 50%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const Item = styled.div`
  padding: 13px 15px;

  @media (max-width: 480px) {
    padding: 8px 0;
  }
`

const Title = styled.h2`
  background: ${props => props.theme.colors.primary};
  color: #fff;
  font-weight: 300;
  margin: 0;
  padding: 12px 24px;
  font-size: 1.315rem;
  line-height: 1.7;
  cursor: pointer;
  position: relative;
  padding-right: 50px;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`

const Main = styled.div`
  background: #fff;
`

const Content = styled.div`
  padding: 20px 24px;

  ul {
    list-style-type: none;

    li::before {
      content: '– ';
      text-indent: 15px;
      margin-left: -20px;
      padding-right: 5px;
    }
  }

  ul p {
    margin: 0;
    padding: 0;
    display: inline;
  }
`

const Icon = styled.img`
  display: block;
  position: absolute;
  top: 25px;
  right: 20px;
  width: 22px;
  height: auto;
  transition: transform 0.35s ease;

  @media (max-width: 480px) {
    top: 20px;
  }

  ${props =>
    props.isActive &&
    css`
      transform: rotate(-180deg);
    `}
`

let lastColumnIndex = 0
let lastItemIndex = 0

const Accordion = ({ items }) => {
  const initialState = [
    new Array(items.length).fill(false),
    new Array(items.length).fill(false),
  ]
  const [state, setState] = useState([...initialState])

  const onToggle = (columnIndex, itemIndex) => {
    // If we open a new one, close others first
    if (!state[columnIndex][itemIndex]) {
      // Close previous first.
      const newState = [...state]
      newState[lastColumnIndex][lastItemIndex] = false
      setState(newState)
    }

    lastColumnIndex = columnIndex
    lastItemIndex = itemIndex

    const newState = [...state]
    newState[columnIndex][itemIndex] = !state[columnIndex][itemIndex]
    setState(newState)
  }

  const columnedItems = items.reduce(
    function(accumulator, currentValue, currentIndex) {
      const columnIndex = currentIndex % 2 === 0 ? 1 : 0 // test for is even
      accumulator[columnIndex].push(currentValue)
      return accumulator
    },
    [[], []]
  )

  return (
    <>
      <Items>
        {columnedItems.map((items, columnIndex) => (
          <Column key={columnIndex}>
            {items.map((item, itemIndex) => (
              <Item key={itemIndex}>
                <Title onClick={() => onToggle(columnIndex, itemIndex)}>
                  {item.title}
                  <Icon
                    src={arrowDown}
                    alt=""
                    isActive={state[columnIndex][itemIndex]}
                  />
                </Title>
                <AnimateHeight
                  duration={500}
                  height={state[columnIndex][itemIndex] ? 'auto' : 0}
                >
                  <Main>
                    <Content>
                      {documentToReactComponents(item.content, {
                        renderText: text =>
                          text
                            .split('\n')
                            .flatMap((text, i) => [i > 0 && <br />, text]),
                      })}
                    </Content>
                  </Main>
                </AnimateHeight>
              </Item>
            ))}
          </Column>
        ))}
      </Items>
      {/*<pre>{JSON.stringify(state, null, 2)}</pre>*/}
    </>
  )
}

export default Accordion
